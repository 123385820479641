import React, { useEffect, useState } from "react";
import UserContext from "./UserContext";
import { StorageServices } from "utils";

const UserContextProvider = ({ children }) => {
  const [token, setToken] = useState(() => localStorage.getItem("token"));
  const [masterData, setMasterData] = useState(null);
  const [genrateOtp, setGenrateOtp] = useState(false);
  const [isSidebar, setToggleSidebar] = useState(false);
  const [showAddAcountModal, setShowAddAcountModal] = useState(false);
  const [showCreateCompanyModal, setShowCreateCompanyModal] = useState(false);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [commonError, setCommonError] = useState("");
  const [userData, setUserData] = useState();
  const [userDetails, setUserDetails] = useState();
  const [allMenu, setAllMenu] = useState([]);
  const [globCompanyId, setGlobCompanyId] = useState("");
  const [mastersChild, setMastersChild] = useState([]);
  const [settingChild, setSettingChild] = useState([]);
  const [reportChild, setReportChild] = useState([]);
  const [accountingChild, setAccountingChild] = useState([]);
  const [globTripDetails, setGlobTripDetails] = useState();
  const [bankDetails, setBankDetails] = useState("");
  const [modalZIndex, setModalZIndex] = useState("");
  const [isMaterialIndex, setIsMaterialIndex] = useState("");
  const [fromStationToUnit, setFromStationToUnit] = useState({});
  const [lrDetialsFrom, setLrDetialsFrom] = useState("");
  const [invoiceBillRaiseData, setInvoiceBillRaiseData] = useState({});
  const [sidebarActive, setSidebarActive] = useState();
  const [sideBarRoutingManage, setSidebarRoutingManage] = useState({});
  const [dashboardLrStatus, setDashboardLrStatus] = useState('')
  const [isDriverName, setIsDriverName] = useState('');
  const [connectionDetailsGlobal, setConnectionDetailsGlobal] = useState({});
  const [dispatchIndent,setDispatchIndent] = useState("")

  return (
    <UserContext.Provider
      value={{
        setDispatchIndent,
        dispatchIndent,
        setBankDetails,
        bankDetails,
        mastersChild,
        setMastersChild,
        settingChild,
        setSettingChild,
        reportChild,
        setReportChild,
        accountingChild,
        setAccountingChild,
        setGlobCompanyId,
        globCompanyId,
        allMenu,
        setAllMenu,
        userDetails,
        setUserDetails,
        setUserData,
        userData,
        commonError,
        setCommonError,
        setCities,
        cities,
        states,
        setStates,
        masterData,
        setMasterData,
        genrateOtp,
        setGenrateOtp,
        isSidebar,
        setToggleSidebar,
        token,
        setToken,
        showAddAcountModal,
        setShowAddAcountModal,
        showCreateCompanyModal,
        setShowCreateCompanyModal,
        setGlobTripDetails: setGlobTripDetails,
        globTripDetails: globTripDetails,
        setModalZIndex,
        modalZIndex,
        setIsMaterialIndex,
        isMaterialIndex,
        setFromStationToUnit,
        fromStationToUnit,
        setLrDetialsFrom,
        lrDetialsFrom,
        invoiceBillRaiseData,
        setInvoiceBillRaiseData,
        setSidebarActive,
        sidebarActive,
        setSidebarRoutingManage,
        sideBarRoutingManage,
        setDashboardLrStatus,
        dashboardLrStatus,
        setIsDriverName,
        isDriverName,
        setConnectionDetailsGlobal,
        connectionDetailsGlobal,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
