import { City, State } from "country-state-city";
import { API, axiosInstance } from "lib";
import StorageService from "utils/StorageServices/StorageServices";


export const allCities = () => {
    const filteredcity =
      City &&
      City.getCitiesOfCountry("IN")

    const optionsCity = filteredcity?.map((d) => {
      return {
        ...d,
        label: `${d?.name}, ${d?.stateCode}`,
        value:d?.name
      };
    });
    
    StorageService.setCities(JSON.stringify([...optionsCity]))
  };

  export const allStates = ()=>{
  const FilterState = State && State.getStatesOfCountry("IN")
  const optionsState = FilterState?.map((d) => {
    return {
      ...d,
    };
  });
 
  StorageService.setStates(JSON.stringify([...optionsState]))
};


export function handleKeyDown(event) {
  
    function isValidNumber(val) {
      return !!(!isNaN(val) && !isNaN(parseInt(val)));
    }
    function isSpace(str) {
      return str === " ";
    }
    function isWhitelistedKey(key) {
      const whitelistedActions = [
        "Backspace",
        "ArrowLeft",
        "ArrowRight",
        "ArrowUp",
        "ArrowDown",
        "Tab",
        "Delete",
        "." //decimal separator
      ];
      return whitelistedActions.includes(key);
    }
    if (isWhitelistedKey(event.key)) {
      // let it through
    } else if (isSpace(event.key) || !isValidNumber(event.key)) {
      event.preventDefault();
    }
  
  }

export const getMasterData = (data = {}) => {
    return axiosInstance
        .get(
            API.GET_MASTER_DATA, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.name === 'TypeError') {
                
                console.error('Network error:', error.message);
            } else {
                       console.error('Error:', error.message);
            }
        });
};

export const getCompanyList = (data = {}) => {
    return axiosInstance
        .get(
            API.GET_MASTER_DATA, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.name === 'TypeError') {
                
                console.error('Network error:', error.message);
            } else {
                       console.error('Error:', error.message);
            }
        });
};

export const getDashbordData = (data = {}) => {
    return axiosInstance
        .post(
            API.GET_COMPANY, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.name === 'TypeError') {
                
                console.error('Network error:', error.message);
            } else {
                       console.error('Error:', error.message);
            }
        });
};

export const getMasterRole = (data = {}) => {
  return axiosInstance
      .post(
          API.GET_MASTER_ROLE, data)
      .then((response) => {
          return response.data;
      })
      .catch((error) => {
        if (error.name === 'TypeError') {
            
            console.error('Network error:', error.message);
        } else {
               console.error('Error:', error.message);
        }
    });
};

export const getRole = (data = {}) => {
  return axiosInstance
      .post(
          API.ROLE, data)
      .then((response) => {
          return response.data;
      })
      .catch((error) => {
        if (error.name === 'TypeError') {
            
            console.error('Network error:', error.message);
        } else {
               console.error('Error:', error.message);
        }
    });
};

export const deleteRole = (data = {}) => {
  return axiosInstance
      .post(
          API.ROLE_DELETE, data)
      .then((response) => {
          return response.data;
      })
      .catch((error) => {
        if (error.name === 'TypeError') {
            
            console.error('Network error:', error.message);
        } else {
               console.error('Error:', error.message);
        }
    });
};

export const consignorGroup = (data = {}) => {
  return axiosInstance
      .post(
          API.CONSIGNOR_GROUP, data)
      .then((response) => {
          return response.data;
      })
      .catch((error) => {
        if (error.name === 'TypeError') {
            
            console.error('Network error:', error.message);
        } else {
               console.error('Error:', error.message);
        }
    });
};

export const getUser = (data = {}) => {
  return axiosInstance
      .post(
          API.USER, data)
      .then((response) => {
          return response.data;
      })
      .catch((error) => {
        if (error.name === 'TypeError') {
            
            console.error('Network error:', error.message);
        } else {
               console.error('Error:', error.message);
        }
    });
};

export const deleteUser = (data = {}) => {
  return axiosInstance
      .post(
          API.DELETE_USER, data)
      .then((response) => {
          return response.data;
      })
      .catch((error) => {
            if (error.name === 'TypeError') {
                
                console.error('Network error:', error.message);
            } else {
                       console.error('Error:', error.message);
            }
        });
};


export const getallowedmenus = (data = {}) => {
  return axiosInstance
      .post(
          API.MENU, data)
      .then((response) => {
          return response.data;
      })
      .catch((error) => {
        if (error.name === 'TypeError') {
            
            console.error('Network error:', error.message);
        } else {
               console.error('Error:', error.message);
        }
    });
};


export const getRoleDetail = (data = {}) => {
    return axiosInstance
        .post(
            API.ROLE_DETAILS, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.name === 'TypeError') {
                
                console.error('Network error:', error.message);
            } else {
                       console.error('Error:', error.message);
            }
        });
  };

  export const updateRoles = (data = {}) => {
    return axiosInstance
        .post(
            API.ROLE_UPDATE, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.name === 'TypeError') {
                
                console.error('Network error:', error.message);
            } else {
                       console.error('Error:', error.message);
            }
        });
  };


  export const getAllAccount = (data = {}) => {
    return axiosInstance
        .post(
            API.ALL_ACCOUNT, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.name === 'TypeError') {
                
                console.error('Network error:', error.message);
            } else {
                       console.error('Error:', error.message);
            }
        });
  };

  export const getAllRoutes = (data = {}) => {
   
    return axiosInstance
        .post(
            API.ALL_ROUTE, data)
        .then((response) => {
          
            return response.data;
        })
        .catch((error) => {
            if (error.name === 'TypeError') {
                
                console.error('Network error:', error.message);
            } else {
                       console.error('Error:', error.message);
            }
        });
  };

  export const getAllVehicleNumber = (data = {}) => {
   
    return axiosInstance
        .post(
            API.ALL_VEHICLE_NUMBER, data)
        .then((response) => {
          
            return response.data;
        })
        .catch((error) => {
            if (error.name === 'TypeError') {
                
                console.error('Network error:', error.message);
            } else {
                       console.error('Error:', error.message);
            }
        });
  };

  export const getAllDriver = (data = {}) => {
   
    return axiosInstance
        .post(
            API.ALL_DRIVERS, data)
        .then((response) => {
          
            return response.data;
        })
        .catch((error) => {
            if (error.name === 'TypeError') {
                
                console.error('Network error:', error.message);
            } else {
                       console.error('Error:', error.message);
            }
        });
  };

  export const getAllSupplier = (data = {}) => {
   
    return axiosInstance
        .post(
            API.ALL_SUPPLIER, data)
        .then((response) => {
          
            return response.data;
        })
        .catch((error) => {
            if (error.name === 'TypeError') {
                
                console.error('Network error:', error.message);
            } else {
                       console.error('Error:', error.message);
            }
        });
  };

  export const getAllLrApi = (data = {}) => {
   
    return axiosInstance
        .post(
            API.ALL_LR, data)
        .then((response) => {
          
            return response.data;
        })
        .catch((error) => {
            if (error.name === 'TypeError') {
                
                console.error('Network error:', error.message);
            } else {
                       console.error('Error:', error.message);
            }
        });
  };

  export const getTrip = (data = {}) => {
    return axiosInstance
        .post(
            API.TRIP, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.name === 'TypeError') {
                
                console.error('Network error:', error.message);
            } else {
                       console.error('Error:', error.message);
            }
        });
  };

  export const getDispatchedTrip = (data = {}) => {
    return axiosInstance
        .post(
            API.DISPATCH_TRIP, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.name === 'TypeError') {
                
                console.error('Network error:', error.message);
            } else {
                       console.error('Error:', error.message);
            }
        });
  };
  
  export const getTransactionList = (data = {}) => {
    return axiosInstance
        .post(
            API.TRANSACTION, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.name === 'TypeError') {
                
                console.error('Network error:', error.message);
            } else {
                       console.error('Error:', error.message);
            }
        });
  };
  
  export const getFreightMasterApi = (data = {}) => {
    return axiosInstance
        .post(
            API.FREIGHT_TYPE, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.name === 'TypeError') {
                
                console.error('Network error:', error.message);
            } else {
                       console.error('Error:', error.message);
            }
        });
  };

  export const getVenderBankList = (data = {}) => {
    return axiosInstance
        .post(
            API.BANK_LIST, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.name === 'TypeError') {
                
                console.error('Network error:', error.message);
            } else {
                       console.error('Error:', error.message);
            }
        });
  };

  export const getAccountFreghtList = (data = {}) => {
    return axiosInstance
        .post(
            API.CHART_OF_ACCOUNT, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.name === 'TypeError') {
                
                console.error('Network error:', error.message);
            } else {
                       console.error('Error:', error.message);
            }
        });
  };

  export const getAccountList = (data = {}) => {
    return axiosInstance
        .post(
            API.ACCOUNT, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.name === 'TypeError') {
                
                console.error('Network error:', error.message);
            } else {
                       console.error('Error:', error.message);
            }
        });
  };

  export const getUserAccount = (data = {}) => {
    return axiosInstance
        .post(
            API.GET_USER_ACCOUNT, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.name === 'TypeError') {
                
                console.error('Network error:', error.message);
            } else {
                       console.error('Error:', error.message);
            }
        });
  };

  export const lrListofTrip = (data = {}) => {
    return axiosInstance
        .post(
            API.LR_LIST_TRIP, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.name === 'TypeError') {
                
                console.error('Network error:', error.message);
            } else {
                       console.error('Error:', error.message);
            }
        });
  };
  
  export const getExpense = (data = {}) => {
    return axiosInstance
        .post(
            API.EXPENSE, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.name === 'TypeError') {
                
                console.error('Network error:', error.message);
            } else {
                       console.error('Error:', error.message);
            }
        });
  };

  export const getUserAccountList = (data = {}) => {
    return axiosInstance
        .post(
            API.USER_ACCOUNT, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.name === 'TypeError') {
                
                console.error('Network error:', error.message);
            } else {
                       console.error('Error:', error.message);
            }
        });
  };

  export const getAccountingUser= (data = {}) => {
    return axiosInstance
        .post(
            API.ACCOUNTING_GET_USER, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.name === 'TypeError') {
                
                console.error('Network error:', error.message);
            } else {
                       console.error('Error:', error.message);
            }
        });
  };


  export const getAccountingTransaction= (data = {}) => {
    return axiosInstance
        .post(
            API.ACCOUNTING_TRANSACTION, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.name === 'TypeError') {
                
                console.error('Network error:', error.message);
            } else {
                       console.error('Error:', error.message);
            }
        });
  };
  
  
  export const getPayment= (data = {}) => {
    return axiosInstance
        .post(
            API.PAYMENT, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.name === 'TypeError') {
                
                console.error('Network error:', error.message);
            } else {
                       console.error('Error:', error.message);
            }
        });
  };
  
  export const getConsignorTransactionList= (data = {}) => {
    return axiosInstance
        .post(
            API.CONSIGNOR_TRAN, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.name === 'TypeError') {
                
                console.error('Network error:', error.message);
            } else {
                       console.error('Error:', error.message);
            }
        });
  };

  export const getLastTrip = (data = {}) => {
    return axiosInstance
        .post(
            API.LAST_TRIP, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.name === 'TypeError') {
                
                console.error('Network error:', error.message);
            } else {
                       console.error('Error:', error.message);
            }
        });
  };
  
  export const getBuiltyApi = (data = {}) => {
    return axiosInstance
        .post(
            API.BUILTY, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.name === 'TypeError') {
                
                console.error('Network error:', error.message);
            } else {
                       console.error('Error:', error.message);
            }
        });
  };
  

  export const getTripCount = (data = {}) => {
    return axiosInstance
        .post(
            API.TRIP_COUNT, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.name === 'TypeError') {
             
                console.error('Network error:', error.message);
            } else {
              
                console.error('Error:', error.message);
            }
        });
  };


  export const getUserPermissionApi = (data = {}) => {
    return axiosInstance
        .post(
            API.USER_PERMISSION, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.name === 'TypeError') {
                
                console.error('Network error:', error.message);
            } else {
               
                console.error('Error:', error.message);
            }
        });
  };

  export const getAddUserBranch = (data = {}) => {
    return axiosInstance
        .post(
            API.GET_ADD_USER_BRANCH, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.name === 'TypeError') {
               
                console.error('Network error:', error.message);
            } else {
               
                console.error('Error:', error.message);
            }
        });
}



export const vehicleGroup = (data = {}) => {
    return axiosInstance
        .post(
            API.VEHICLE_GROUP, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
          if (error.name === 'TypeError') {
             
              console.error('Network error:', error.message);
          } else {
              
              console.error('Error:', error.message);
          }
      });
  };

  export const getTotalFreightDetais = (data = {}) => {
    return axiosInstance
        .post(
            API.TOTAL_FREIGHT_DETAILS, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.name === 'TypeError') {
               
                console.error('Network error:', error.message);
            } else {
               
                console.error('Error:', error.message);
            }
        });
  };
  

  export const getAddressListApi = (data = {}) => {
    return axiosInstance
        .post(
            API.COMMON_DROPDOWN + `get_addressbook_for_dropdown`, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.name === 'TypeError') {
               
                console.error('Network error:', error.message);
            } else {
               
                console.error('Error:', error.message);
            }
        });
  };

  export const getMarketVisiblityApi = (data = {}) => {
    return axiosInstance
        .post(
            API.MARKET_VISIBLITY_LIST , data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.name === 'TypeError') {
               
                console.error('Network error:', error.message);
            } else {
               
                console.error('Error:', error.message);
            }
        });
  };
  
