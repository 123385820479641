import { dispatchIndentApi, indentCountApi, indentListApi } from "_services";
import {
  getBranchDropdownListData,
  getConsignorDropdownListData,
} from "_services/dropdownServices/dropdownService";
import { ButtonHeading, CustomSelect } from "components";
import images from "constants/images";
import { UserContext } from "context";
import { utils } from "helper";
import { AddIndentModal, ConfirmModalFor, IndentAndBidDetailModal } from "modals";
import MastersFilterModal from "modals/MastersFilterModal";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  IoChevronBack,
  IoChevronDownCircleOutline,
  IoChevronForward,
} from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import StorageService from "utils/StorageServices/StorageServices";
import { number } from "yup";

const Plan = () => {

  

  const navigate = useNavigate()
  const { masterData ,setDispatchIndent,dispatchIndent} = useContext(UserContext);
  const [showAddIndent, setShowAddIndent] = useState(false);
  const [showPlanDetail, setShowPlanDetail] = useState(false);
  const [indentList, setIndentList] = useState([]);
  const [indentCount, setIndentCount] = useState([]);
  const [responseList, setResponseList] = useState([]);
  const [filter, setFilter] = useState({
    start_date: "",
    end_date: "",
    plan_status: "",
    bid_status: "",
    responses: "",
    limit: 10,
    offset: 1,
    search: "",
    consignor_id: "",
    branch_id: "",
  });
  const itemsPerPage = 10;
  const [totalItems, setTotalItems] = useState(0);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [listData, setListData] = useState({
    consignorList: [],
    branchList: [],
  });

  const [indentId,setIndentId] = useState('')
  const [activeTabStatus,setActiveTabStatus] = useState(0)
  const [showBackModal, setShowBackModal] = useState(false);
  const [loading,setLoading] = useState(false)

  useEffect(() => {
    getIndentCount();
    getMasterDetails();
  }, []);

  useEffect(() => {
    fetchData();
  }, [masterData]);

  useEffect(() => {
    getMasterDetails();
    if (filter?.plan_status != 1) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        bid_status: 0,
        responses: 0,
      }));
      return;
    }else{
      setFilter((prevFilter) => ({
        ...prevFilter,
        bid_status: indentCount?.bid_status[0].id,
        responses: indentCount?.responses[0].id,
      }));
    }
    
  }, [filter?.plan_status, indentCount]);

  useEffect(() => {
    getMasterDetails();
    if (filter?.plan_status ) {
      if(filter?.bid_status == 0 &&
        filter?.responses == 0){
          getIndentList();
          setFilter({ ...filter, offset: 1 });
        }
    }
  }, [filter?.plan_status]);

  useEffect(() => {
    getMasterDetails();
    if (filter?.plan_status &&  filter?.bid_status !== undefined &&
      filter?.bid_status !== null) {
      getIndentList();
      setFilter({ ...filter, offset: 1 });
    }
  }, [filter?.bid_status, filter?.responses]);

  useEffect(() => {
    getMasterDetails();
    if (filter?.plan_status) {
      getIndentList();
      getIndentCount();
    }
  }, [
    filter?.offset,
    filter?.consignor_id,
    filter?.branch_id,
    filter?.start_date,
    filter?.end_date,
    filter?.search,
  ]);

  const arryOptions = [
    { label: "Arjun", value: "01" },
    { label: "Anvit", value: "02" },
  ];

  const fetchData = async () => {
    try {
      // setLoading(true);
      const [consignorRes, branchRes] = await Promise.all([
        getConsignorList(),
        getBranchList(),
      ]);

      const consignorList = formatList(
        consignorRes?.data?.consignor_list,
        "name",
        "_id",
        "All"
      );
      const branchList = formatList(
        branchRes?.data?.branch_list,
        "branch",
        "_id",
        "All"
      );

      setListData({
        consignorList,
        branchList,
      });

      // setLoading(false);
    } catch (error) {
      // setLoading(false);
      console.error("Error fetching data", error);
    }
  };

  const commonFetchFuction = async (
    apiMethod,
    payload = {},
    transformer = (res) => res
  ) => {
    try {
      const res = await apiMethod(payload);
      if (res?.responseCode === 200) {
        return transformer(res);
      } else {
        toast.error(res?.responseMessage);
      }
    } catch (error) {
      toast.error(error?.message || "Something went wrong");
    }
  };

  const getConsignorList = async () => {
    let object = { company_id: StorageService.getCompanyId() };
    return commonFetchFuction(getConsignorDropdownListData, object);
  };

  const getBranchList = async () => {
    let object = { company_id: StorageService.getCompanyId() };
    return commonFetchFuction(getBranchDropdownListData, object);
  };

  const formatList = (list, labelKey, valueKey, defaultOption = null) => {
    const formattedList = list?.map((item) => ({
      ...item,
      label: item[labelKey],
      value: item[valueKey],
    }));
    return defaultOption
      ? [{ label: defaultOption, value: "" }, ...formattedList]
      : formattedList;
  };

  const getIndentList = async () => {
    const payload = {
      company_id: StorageService?.getCompanyId(),
      branch_id: filter?.branch_id,
      consignor_id: filter?.consignor_id,
      vehicle_type_id: "",
      start_date: filter?.start_date
        ? moment(filter?.start_date).format("YYYY-MM-DD")
        : "",
      end_date: filter?.end_date
        ? moment(filter?.end_date).format("YYYY-MM-DD")
        : "",
      search: filter?.search,
      plan_status: filter?.plan_status,
      bid_status: filter?.bid_status,
      responses: filter?.responses,
      limit: filter?.limit,
      offset: filter?.offset,
    };

    try {
      const res = await indentListApi(payload);

      if (res?.responseCode == 200) {
        const resdata = res?.data?.plan_list?.map((i) => {
          return { ...i, visiblity: 0 };
        });
        setIndentList(resdata);
        setTotalItems(res?.data?.total_record);
      } else {
        toast.error(res?.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const getIndentCount = async () => {
    const payload = {
      company_id: StorageService?.getCompanyId(),
      branch_id: filter?.branch_id,
      consignor_id: filter?.consignor_id,
      vehicle_type_id: "",
      start_date: filter?.start_date
        ? moment(filter?.start_date).format("YYYY-MM-DD")
        : "",
      end_date: filter?.end_date
        ? moment(filter?.end_date).format("YYYY-MM-DD")
        : "",
      search: filter?.search,
    };

    try {
      const res = await indentCountApi(payload);

      if (res?.responseCode == 200) {
        setIndentCount(res?.data);
        setFilter({
          ...filter,
          plan_status: res?.data?.plan_status[0].id,
          bid_status: res?.data?.bid_status[0].id,
          responses: res?.data?.responses[0].id,
        });
      } else {
        toast.error(res?.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const getMasterDetails = () => {
    const list = masterData?.plan_responses_list?.map((i) => {
      return {
        label: i?.name,
        value: i?.id,
      };
    })
    setResponseList(list);
  };

  const togglePlanDetail = (id,status) => {
    setIndentId(id)
    setShowPlanDetail(true);
    setActiveTabStatus(status)
  };

  const handleFilter = (id, title) => {
    if (title == "plan") {
      if (id !== filter?.plan_status) {
        setFilter({ ...filter, plan_status: id });
      }
    }

    if (title == "bid") {
      if (id !== filter?.bid_status) {
        setFilter({ ...filter, bid_status: id });
      }
    }

    if (title == "responses") {
      if (id !== filter?.responses) {
        setFilter({ ...filter, responses: id });
      }
    }
  };

  const handlePageChange = (page) => {
    setFilter((s) => ({ ...s, offset: page }));
  };

  const renderPagination = () => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const currentPage = filter.offset;
    let pages = [];
    const createPageItem = (i) => (
      <li key={i} className={`page-item ${i === currentPage ? "active" : ""}`}>
        <a className="page-link _avPointer" onClick={() => handlePageChange(i)}>
          {i}
        </a>
      </li>
    );

    const rangeStart = Math.max(2, currentPage - 2);
    const rangeEnd = Math.min(totalPages - 1, currentPage + 2);

    pages.push(createPageItem(1));

    if (rangeStart > 2) {
      pages.push(
        <li key="dots-before" className="page-item disabled">
          <span className="page-link">...</span>
        </li>
      );
    }

    for (let i = rangeStart; i <= rangeEnd; i++) {
      pages.push(createPageItem(i));
    }

    if (rangeEnd < totalPages - 1) {
      pages.push(
        <li key="dots-after" className="page-item disabled">
          <span className="page-link">...</span>
        </li>
      );
    }

    if (totalPages > 1) {
      pages.push(createPageItem(totalPages));
    }

   

    return (
      <ul className="pagination ">
        <li className={`page-item ${filter?.offset === 1 ? "disabled" : ""}`}>
          <a
            className="page-link _avPointer"
            onClick={() => handlePageChange(filter?.offset - 1)}
          >
            <IoChevronBack />
          </a>
        </li>
        {pages}
        <li
          className={`page-item ${
            filter?.offset === totalPages ? "disabled" : ""
          }`}
        >
          <a
            className="page-link _avPointer"
            onClick={() => handlePageChange(filter?.offset + 1)}
          >
            <IoChevronForward />
          </a>
        </li>
      </ul>
    );
  };

  const planFromLoc = (loc) => {
    return loc.map((item) => item.from_city).join(" + ");
  };

  const planToLoc = (loc) => {
    return loc.map((item) => item.to_city).join(" + ");
  };

  

  const callAllApi = () => {
    getIndentCount();
    getIndentList();
    setIndentId('')
  };

  const handleEditIndent = (id) =>{
    setIndentId(id)
    setShowAddIndent(true)
  }

  const handleDispatch = async() =>{
    
const payload ={
  company_id:StorageService.getCompanyId(),
  plan_id:showBackModal
}

    try {
      setLoading(true)
      const res = await dispatchIndentApi(payload)
  
      if(res?.responseCode==200){
        setShowBackModal(false)
        setDispatchIndent(res?.data?.plan)
        navigate("/trip/addtrip/createtrip")
        setLoading(false)
      }else{
        toast.error(res?.responseMessage)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
    }
  }

  const backModalProps = {
    show: showBackModal,
    closeModal: (e) => setShowBackModal(false),
    title: "Dispatch Indent",
    submitTitle: "Confirm",
    onSubmit:handleDispatch,
    confirmModal: "confirmModal",
    icon:images.TRUCKICON,
    disabled:loading,
    isLoadding:loading
  };


  
  return (
    <>
      <ButtonHeading
        title="Indent"
        onClick={() => setShowAddIndent(!showAddIndent)}
        buttonTitle="Add Indent"
        onClickFilterModal={(e) => setShowFilterModal(true)}
        filter={filter}
        setFilter={setFilter}
        showSearchFilter={{
          search: true,
          filterOption: true,
          filterCount: true,
        }}
      />
      <div className="_indentView_flx">
        <div className="indentSide_bx">
          <div className="_indtsLs_flx">
            <div className="_indtsList">
              <h5>PLAN STATUS</h5>
              <ul className="nav nav-tabs" id="myIndentTab" role="tablist">
                {indentCount?.plan_status?.map((item) => (
                  <li className="nav-item" role="presentation" key={item.id}>
                    <button
                      className={`nav-link ${
                        filter?.plan_status === item.id ? "active" : ""
                      }`}
                      id={`${item.name.toLowerCase().replace(/\s+/g, "-")}-tab`}
                      data-bs-toggle="tab"
                      data-bs-target={`#${item.name
                        .toLowerCase()
                        .replace(/\s+/g, "-")}-tab-pane`}
                      type="button"
                      role="tab"
                      aria-controls={`${item.name
                        .toLowerCase()
                        .replace(/\s+/g, "-")}-tab-pane`}
                      aria-selected={filter?.plan_status === item.id}
                      onClick={() => handleFilter(item.id, "plan")}
                    >
                      <span>{item.name}</span> <span>{item.count}</span>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          {filter?.plan_status == 1 &&  <>
              <div className="_indtsList">
                <h5>BIDS 500</h5>
                <div className="_bidsLs_flx">
                  {indentCount?.bid_status?.map((bid) => {
                    return (
                      <div className="_bidsList" key={bid.id}>
                        <input
                          className="form-check-input _avPointer"
                          id={`bid-${bid.id}`}
                          type="radio"
                          name="bidList"
                          checked={filter?.bid_status === bid.id}
                          onChange={() => handleFilter(bid.id, "bid")}
                          disabled={filter?.plan_status != 1}
                        />
                        <label htmlFor={`bid-${bid.id}`}>
                          <span>{bid.name}</span>
                          <span>{bid.count}</span>
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="_indtsList">
                <h5>RESPONSES</h5>
                <div className="_bidsLs_flx">
                  {indentCount?.responses?.map((bid) => {
                    return (
                      <div className="_bidsList" key={bid.id}>
                        <input
                          className="form-check-input _avPointer"
                          id={`responses-${bid.id}`}
                          type="radio"
                          name="responseList"
                          checked={filter?.responses === bid.id}
                          onChange={() => handleFilter(bid.id, "responses")}
                          disabled={filter?.plan_status != 1}
                        />
                        <label htmlFor={`responses-${bid.id}`}>
                          <span>{bid.name}</span>
                          <span>{bid.count}</span>
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </>}
          </div>
        </div>

        <div className="_indTabl_right">
          <div className="_indentTable _planLTable">
            <div class="accordion" id="indentTableAccordian">
              <table>
                <thead>
                  <tr>
                    <th>Indent Date</th>
                    <th>Indent No.</th>
                    <th>Party</th>
                    <th>From Station</th>
                    <th>To Station</th>
                    <th>Branch Name</th>
                    <th>Fleet Type</th>
                    <th>Material Type</th>
                    <th>View</th>
                  </tr>
                </thead>
                {indentList?.map((indent, index) => {
                 
                  const isFirstItem = index === 0;
                  return (
                    <tbody class="accordion-item">
                      <tr style={{"cursor":"pointer"}} onClick={()=>handleEditIndent(indent?._id)} >
                        <td>
                          <div className="_dateNtime _pDate">
                            <p className="_tblText_inDent">
                              {utils?.formatDateMonth(indent?.indent_date)}
                            </p>
                            <p className="_lgtText_inDent">
                              {utils?.formatTime(indent?.indent_date)}
                            </p>
                          </div>
                        </td>

                        <td>
                          <div className="__locationWid_bx" >
                            <p className="_tblText_inDent curser_pointer">
                              {indent?.indent_number}
                            </p>
                          </div>
                        </td>

                        <td>
                          <div className="__locationWid_bx">
                            <p className="_tblText_inDent">
                              {indent?.consignor_id
                                ? indent?.consignor_id?.name
                                : "Self"}
                            </p>
                          </div>
                        </td>

                        <td>
                          <div className="__locationWid_bx">
                            <p className="_tblText_inDent">
                              {planFromLoc(indent?.from_location)}
                            </p>
                          </div>
                        </td>

                        <td>
                          <div className="__locationWid_bx">
                            <p className="_tblText_inDent">
                              {planToLoc(indent?.to_location)}
                            </p>
                          </div>
                        </td>

                        <td>
                          <div className="__locationWid_bx">
                            <p className="_tblText_inDent">
                              {indent?.branch_id?.branch}
                            </p>
                          </div>
                        </td>

                        <td>
                          <div className="_dateNtime">
                            <div className="__locationWid_bx">
                              <p className="_tblText_inDent">
                                {indent?.lorry_type_id?.lorry_type}
                              </p>
                            </div>
                            <p className="_lgtText_inDent">
                              {indent?.vehicle_type_id?.name}
                            </p>
                          </div>
                        </td>

                        <td>
                          <div className="__locationWid_bx">
                            <p className="_tblText_inDent">
                              {indent?.material_type_id?.name}
                            </p>
                          </div>
                        </td>

                        <td>
                          <button
                            class={`accordion-button _plnChev_btn ${
                              isFirstItem ? "" : "collapsed"
                            }`}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#${indent?._id}`}
                            aria-expanded={isFirstItem ? "true" : "false"}
                            aria-controls={`${indent?._id}`}
                            onClick={(event) => {
                              event.stopPropagation(); 
                            }}
                          >
                            <IoChevronDownCircleOutline />
                          </button>
                        </td>
                      </tr>

                      <tr className="collapseRow">
                        <td colSpan={9}>
                          <div
                            id={`${indent?._id}`}
                            className={`accordion-collapse collapse ${
                              isFirstItem ? "show" : ""
                            }`}
                            data-bs-parent="#indentTableAccordian"
                          >
                            <div class="accordion-body">
                              {/* {indent?.to_location?.map((loca) => {
                                return ( */}
                              <>
                                {indent?.po_list?.map((po) => {
                                  return (
                                    <div className="_innrPO_trs">
                                      <div className="_poDetails">
                                        <span>PO Date</span>
                                        <p>
                                          {utils?.formatDateMonth(po.po_date)}
                                        </p>
                                      </div>

                                      <div className="_poDetails">
                                        <span>PO Number</span>
                                        <p>{po?.po_number}</p>
                                      </div>

                                      <div className="_poDetails">
                                        <span>Party</span>
                                        <p>
                                          {indent?.consignor_id
                                            ? indent?.consignor_id?.name
                                            : "Self"}
                                        </p>
                                      </div>

                                      <div className="_poDetails">
                                        <span>From Station</span>
                                        <p>{po?.from_location}</p>
                                      </div>

                                      <div className="_poDetails">
                                        <span>Pickup Address</span>
                                        <p>{po?.unit_id?.name}</p>
                                      </div>

                                      <div className="_poDetails">
                                        <span>To Station</span>
                                        <p>{po?.to_location}</p>
                                      </div>

                                      <div className="_poDetails">
                                        <span>Drop Address</span>
                                        <p>{po?.consignee_id?.name}</p>
                                      </div>
                                    </div>
                                  );
                                })}
                              </>
                              {/* );
                              })} */}
                            </div>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td colSpan={9}>
                          <div className="d-flex justify-content-between gap-2">
                            <div className="_separateBxs">
                              <button className="_disPatch_ind"  onClick={()=>{
                                if(indent?.plan_status==1){
                                  setShowBackModal(indent?._id)
                                }else{
                                  navigate(`/trip/addtrip/dispatched/${indent?.trip_id}`)
                                }
                                // setIndentId(indent?._id)
                                }}>
                               { indent?.plan_status==1?"Dispatch":"Dispatched"}
                              </button>

                              <span className="activeStatus">
                               
                                {masterData?.plan_status[indent?.plan_status]}
                              </span>

                              <div className="_bidBtn_fx">
                                <div
                                  className="_sepBid_fx"
                                  onClick={()=>togglePlanDetail(indent,0)}
                                >
                                  <span className="_bidTxt">New Bid</span>
                                  <span className="_bidCount">{indent?.new_bid_count?indent?.new_bid_count:0}</span>
                                </div>

                                <div
                                  className="_sepBid_fx"
                                  onClick={()=>togglePlanDetail(indent,1)}
                                >
                                  <span className="_bidTxt">Accepted</span>
                                  <span className="_bidCount">{indent?.accepted_bid_count?indent?.accepted_bid_count:0}</span>
                                </div>

                                <div
                                  className="_sepBid_fx"
                                  onClick={()=>togglePlanDetail(indent,3)}
                                >
                                  <span className="_bidTxt">Closed</span>
                                  <span className="_bidCount">{indent?.closed_bid_count?indent?.closed_bid_count:0}</span>
                                </div>
                              </div>
                              {filter?.plan_status==1 && <div
                                className="_anvSwitcherNew form-check form-switch"
                                style={{ width: "250px" }}
                              >
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="visiblity"
                                  id={`actStatus-${indent._id}`}
                                  autoComplete="off"
                                  checked={Number(indent?.is_allow_visibility)}
                                  onChange={({ target: { name, checked } }) => {
                                    let copyList = [...indentList];
                                    copyList[index].visiblity = checked;

                                    setIndentList(copyList);
                                  }}
                                />
                                <span
                                >
                                  {Number(indent?.is_allow_visibility)
                                    ? " Market Visiblity Active"
                                    : " Market Visiblity Disabled"}
                                </span>
                              </div>}
                            </div>

                            {filter?.plan_status==1 && <div>
                              <CustomSelect
                                options={responseList}
                                value={{
                                  label: `Status : ${
                                    indent?.responses
                                      ? responseList?.find(
                                          (i) => i?.value == indent?.responses
                                        ).label
                                      : ""
                                  }`,
                                }}
                                onSelect={(data) => {}}
                              />
                            </div>}
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td colSpan={9}></td>
                      </tr>
                    </tbody>
                  );
                })}
              </table>
            </div>
            <div className="paginationFt_avt ">{renderPagination()}</div>
          </div>
        </div>
      </div>

      <div
        className={`offcanvas offcanvas-end common_offCanvas mdOffcanvas ${
          showAddIndent ? "show" : ""
        }`}
        tabIndex={-1}
        style={{ visibility: showAddIndent ? "visible" : "hidden" }}
      >
        {showAddIndent && (
          <AddIndentModal
            isOpen={showAddIndent}
            onClose={(e) =>{
              setIndentId("")
              setShowAddIndent(false)}}
            callList={callAllApi}
            id={indentId}
          />
        )}
      </div>
      <div className={showAddIndent ? "offBack_drop" : ""}></div>

      <div
        className={`offcanvas offcanvas-end common_offCanvas xxlOffcanvas ${
          showPlanDetail ? "show" : ""
        }`}
        tabIndex={-1}
        style={{ visibility: showPlanDetail ? "visible" : "hidden" }}
      >
        {showPlanDetail && (
          <IndentAndBidDetailModal
            isOpen={showPlanDetail}
            onClose={(e) => {
              setIndentId("")
              setShowPlanDetail(false)}}
              id={indentId}
              activeStatus={activeTabStatus}
          />
        )}
      </div>
      <div className={showPlanDetail ? "offBack_drop" : ""}></div>

      <div
        className={`offcanvas offcanvas-end common_offCanvas ${
          showFilterModal ? "show" : ""
        }`}
        tabIndex={-1}
        style={{ visibility: showFilterModal ? "visible" : "hidden" }}
      >
        {showFilterModal && (
          <MastersFilterModal
            isOpen={showFilterModal}
            onClose={() => setShowFilterModal(false)}
            filter={filter}
            setFilter={setFilter}
            isShow={{
              dateRange: true,
              allBranch: true,
              consignor: true,
            }}
            list={{
              branch: listData?.branchList,
              consignor: listData?.consignorList,
            }}
            name="plan"
          />
        )}
      </div>
      <div className={showFilterModal ? "offBack_drop" : ""}></div>

      {showBackModal && (
        <ConfirmModalFor {...backModalProps}>
          <>
            <p className="_confirmPrx"> Do you really want Dispatch indent </p>
          </>
        </ConfirmModalFor>
      )}
    </>
  );
};

export default Plan;
