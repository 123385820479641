import { planImport } from "_services";
import {
  getConsignorDropdownListData,
  getContractDropdownListData,
} from "_services/dropdownServices/dropdownService";
import { CommonInput, CommonModal, CustomSelect } from "components";
import { errorSchema, utils } from "helper";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import StorageService from "utils/StorageServices/StorageServices";

const QuickUploadModal = (props) => {
  const initial = {
    company_id:StorageService?.getCompanyId(),
    consignor_id: "",
    contract_id: "",
    branch_id: "",
    file: "",
  };
  const navigate = useNavigate()
  const [form, setForm] = useState(initial);
  const [formError, setFormError] = useState("");
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState({
    consignorList: [],
    contractList: [],
    branchList: [],
  });
 
  useEffect(() => {
    getConsignorList();
  }, []);

 

  useEffect(() => {
    if (form?.consignor_id) {
      getContractList(form?.consignor_id);
    }
  }, [form?.consignor_id]);

  const handleOnChange = async (name, value) => {

    setForm((prevForm) => {
      const updatedForm = {
        ...prevForm,
        [name]: value,
      };

      if (formError) {
        utils
          .checkFormError(
            {
              ...updatedForm,
            },
            errorSchema.addPostIndentSchima
          )
          .then((validationResult) => {
            if (validationResult === true) {
              setFormError("");
            } else {
              setFormError(validationResult);
            }
          });
      }

      return updatedForm;
    });
  };

  //file

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validTypes = [
        "text/csv",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ];
      if (validTypes.includes(file.type)) {
        handleOnChange("file", file);
        setFormError({
          ...formError,
          file: "",
        });
      } else {
        setFormError({
          ...formError,
          file: "Please upload only CSV or XLSX files.",
        });
      }
    }
  };

  //common Api function
  const commonFetchFuction = async (
    apiMethod,
    payload = {},
    transformer = (res) => res
  ) => {
    try {
      const res = await apiMethod(payload);
      if (res?.responseCode === 200) {
        return transformer(res);
      } else {
        toast.error(res?.responseMessage);
      }
    } catch (error) {
      toast.error(error?.message || "Something went wrong");
    }
  };

  //common formate for dropdown

  const formatList = (list, labelKey, valueKey, defaultOption = null) => {
    const formattedList = list?.map((item) => ({
      ...item,
      label: item[labelKey],
      value: item[valueKey],
    }));
    return defaultOption
      ? [{ label: defaultOption, value: "" }, ...formattedList]
      : formattedList;
  };

  //set initial value

  const setInitialFormValues = (name, value) => {
    handleOnChange(name, value || "");
  };

  const getConsignorList = async () => {
    const payload = {
      company_id: StorageService.getCompanyId(),
      limit: 100,
      offset: 1,
      search: "",
    };
    try {
      const consignorRes = await commonFetchFuction(
        getConsignorDropdownListData,
        payload
      );

      setListData({
        ...listData,
        consignorList: formatList(
          consignorRes?.data?.consignor_list,
          "name",
          "_id"
        ),
      });

      setInitialFormValues(
        "consignor_id",
        consignorRes?.data?.consignor_list[0]?._id
      );
    } catch (error) {
      toast.error("Error fetching data", error);
    }
  };

  const getContractList = async (contarct) => {
    const payload = {
      company_id: StorageService.getCompanyId(),
      consignor_id: contarct,
      limit: 100,
      offset: 1,
      search: "",
    };
    try {
      const contractRes = await commonFetchFuction(
        getContractDropdownListData,
        payload
      );

      setListData({
        ...listData,
        contractList: formatList(
          contractRes?.data?.contract_list,
          "name",
          "_id"
        ),
      });

      setInitialFormValues(
        "contract_id",
        contractRes?.data?.contract_list[0]?._id
      );
    } catch (error) {
      toast.error("Error fetching data", error);
    }
  };

  const handleImportFile = async () => {
  
    const formData = new FormData();
    Object.keys(form).forEach((key) => {
      if (key === "file" && form[key]) {
        formData.append(key, form[key]);
      } else {
        formData.append(key, form[key]);
      }
    });

    const validationResult = await utils.checkFormError(
      { ...form },
      errorSchema.planImportSchema
    );
    if (validationResult == true) {
        setLoading(true)
      try {
        const res = await planImport(formData);
     
        if(res?.responseCode ==200){
            setLoading(false)
            toast?.success(res?.responseMessage)
            toast(
              `
              New plan count:${res?.data?.new_plan_count}

              New po count:${res?.data?.new_po_count}

               Re-Direct to planList ...
              `,
                {
                  duration: 3000,
                }
              );
              setTimeout(() => {
              
                if(props?.title =="plan"){
                    navigate("/plan/planlist")
                }
              
              }, 3000);
              props?.closeModal()
        }else{
            setLoading(false)
        }
      } catch (error) {
        toast.error(error)
        
      }
    } else {
     
      setFormError(validationResult);
     
    }
  };

  const inhenceProps = {
    ...props,
    onSubmit: handleImportFile,
    disabled:loading
  };

  return (
    <>
      <CommonModal {...inhenceProps}>
        <div className="addFreight_body">
          <>
            <div className="_endTr_body">
              <CustomSelect
                label="Consignor"
                placeholder={"Select Consignor"}
                options={listData?.consignorList}
                value={listData?.consignorList?.find(
                  (i) => i?.value == form?.consignor_id
                )}
                onSelect={(data)=>{
                  handleOnChange("consignor_id",data?.value)
                }}
              />
            </div>
            <div className="_endTr_body">
              <CustomSelect
                label="Contract"
                placeholder={"Select Contract"}
                options={listData?.contractList}
                value={listData?.contractList?.find(
                  (i) => i?.value == form?.contract_id
                )}
                onSelect={(data)=>{
                  handleOnChange("contract_id",data?.value)
                }}
              />
            </div>{" "}
            {/* <div className="_endTr_body">
              <CustomSelect
                label="Branch"
                placeholder={"Select Branch"}
                options={[]}
              />
            </div> */}
            <div className="_endTr_body">
              <CommonInput
                type="file"
                onChange={handleFileChange}
                accept=".csv, .xlsx"
                error={formError?.file}
              />
            </div>
          </>
        </div>
      </CommonModal>
    </>
  );
};

export default QuickUploadModal;
