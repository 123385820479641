import { Heading } from 'components';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { AddNewConnectionModal, SaveConnectModal, ViewProfileModal } from 'modals';
import { UserContext } from 'context';
import StorageService from 'utils/StorageServices/StorageServices';
import toast from 'react-hot-toast';
import { acceptConnectionRequest, getConnectionCounts, getConnectionListData, getSuggestionListData, rejectConnectionRequest, removeConnectionRequest, sendConnectionRequest, withDrawalConnectionRequest } from '_services';
import CommonBox from './CommonBox';
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import LoadingBar from 'react-top-loading-bar';

let myConnectionTabs = [
  { id: 1, title: "Connections", value: "all_connections", count: 0 },
  { id: 2, title: "Request", value: "request", count: 0 },
  { id: 3, title: "Pending", value: "pending", count: 0 },
  { id: 4, title: "All Members", value: "suggestions", count: 0 },
]

const Invitations = () => {

  const loadingBarRef = useRef(null);
  const { connectionDetailsGlobal } = useContext(UserContext);
  const [showBusinessProfile, setShowBusinessProfile] = useState(false);
  const [connectionData, setConnectionData] = useState([]);
  const [countArray, setCountArray] = useState(myConnectionTabs);
  const [showAddConnection, setShowAddConnection] = useState(false);
  const [showSaveConnect, setShowSaveConnect] = useState(false);

  const [loadingRemove, setLoadingRemove] = useState(false);
  const [loadingConnection, setLoadingConnection] = useState({});
  const [loadingWithDrawal, setLoadingWithDrawal] = useState({});
  const [loadingAccecpt, setLoadingAccecpt] = useState({});

  const [loading, setLoading] = useState(false);

  const [totalCount1, setTotalCount1] = useState(0)
  const [totalCount2, setTotalCount2] = useState(0)
  const [totalCount3, setTotalCount3] = useState(0)
  const [totalCount4, setTotalCount4] = useState(0)

  const [activeTab, setActiveTab] = useState(1);
  const itemsPerPage = 50;
  const [filter, setFilter] = useState({
    limit: 50,
    offset: 1,
    type: 1,
  });

  const [saveModalDirect, setSaveModalDirect] = useState({ isOpenDirect: true, isCloseDirect: true, });

  const fetchConnectionDataList = async () => {
    setLoading(true);
    try {
      if (loadingBarRef.current) {
        loadingBarRef.current.continuousStart(); // Start the loading bar
      }
      if (activeTab === 4) {
        let object = {
          ...filter,
          company_id: StorageService.getCompanyId(),
        }
        delete object?.type;
        const res = await getSuggestionListData(object);
        if (res?.responseCode === 200) {
          setConnectionData(res?.data);
          setLoading(false);
        }
      } else {
        let object = {
          ...filter,
          company_id: StorageService.getCompanyId(),
          type: activeTab // 1 = all connection, 2 = received, 3 = sent
        }
        const res = await getConnectionListData(object);
        if (res?.responseCode === 200) {
          setConnectionData(res?.data)
          setLoading(false);
        }
      }
    } catch (error) {
      toast.error('Server side api error !! ');
      setLoading(false);
    } finally {
      setLoading(false);
      if (loadingBarRef.current) {
        loadingBarRef.current.complete(); // Complete the loading bar
      }
    }
  }

  const fetchCountsData = async () => {
    try {
      if (loadingBarRef.current) {
        loadingBarRef.current.continuousStart(); // Start the loading bar
      }
      let object = {
        company_id: StorageService.getCompanyId(),
      }
      const res = await getConnectionCounts(object);
      if (res?.responseCode === 200) {
        setTotalCount1(res?.data?.all_connection);
        setTotalCount2(res?.data?.request);
        setTotalCount3(res?.data?.pending);
        setTotalCount4(res?.data?.suggestions);
        let dataArray = []
        for (let i = 0; i < myConnectionTabs?.length; i++) {
          for (let j = 0; j < Object.values(res?.data)?.length; j++) {
            if (i == j) {
              dataArray.push({ ...myConnectionTabs[i], count: Object.values(res?.data)[j] })
            }
          }
        }
        setCountArray(dataArray);
      }
    } catch (error) {
      toast.error('Server side api error !! ');
    } finally {
      if (loadingBarRef.current) {
        loadingBarRef.current.complete(); // Complete the loading bar
      }
    }
  }

  let commonProps = {
    isOpen: showBusinessProfile,
    onClose: (e) => setShowBusinessProfile(e)
  }

  useEffect(() => {
    fetchConnectionDataList();
  }, [activeTab, filter])

  useEffect(() => {
    fetchCountsData();
  }, [])

  useEffect(() => {
    if(showBusinessProfile === false){
      fetchConnectionDataList();
    }
  },[showBusinessProfile])

  let profileModal = {
    isOpen: showBusinessProfile,
    activeTabValue: activeTab,
    closeModal: (e) => setShowBusinessProfile(false),
    modalMainClass: "_dashCommon_Modal _viewProfile_modal",
    modalTitle: "View Business Profile",
    loadingRm: loadingRemove,
    removeConnection: (e) => removeConnectionData(e),
    acceptRequest: (e) => acceptRequestForConnection(e),
    withdrawalRequest: (e) => withdrawalRequestForConnection(e),
    nextModal: (e) => setShowSaveConnect(e),
    saveModalDirect: saveModalDirect,
    setSaveModalDirect: (e) => setSaveModalDirect(e)
  }

  let saveModalFunction = () => {
    if (saveModalDirect?.isOpenDirect && saveModalDirect?.isCloseDirect) {
      setShowSaveConnect(false);
    } else {
      setShowBusinessProfile(connectionDetailsGlobal);
      setShowSaveConnect(false);
    }
  }

  let saveConnectModal = {
    isOpen: showSaveConnect,
    closeModal: (e) => saveModalFunction(e),
    modalMainClass: " _saveConnectModal",
    modalTitle: "Save Connect to",
    submitTitle: "Send Connection Request",
    sendRequest: (value) => sendRequestForConnection(value),
    nextModal: (e) => setShowAddConnection(e),
    previousModal: (e) => setShowSaveConnect(e),
  }

  const closeAddModalFunction = (event) => {
    setShowSaveConnect(event);
    setShowAddConnection(!event);
  }

  const addConnectionModal = {
    isOpen: showAddConnection,
    closeModal: (e) => closeAddModalFunction(e),
    modalMainClass: "_dashCommon_Modal _smModal",
    modalTitle: "Add Connection",
    submitTitle: "Continue",
  }

  const handlePageChange = (page) => {
    setFilter((s) => ({ ...s, offset: page }))
  };

  const renderPagination = () => {
    let data = (activeTab === 1 ? totalCount1 : (activeTab === 2 ? totalCount2 : (activeTab === 3 ? totalCount3 : totalCount4)))
    const totalPages = Math.ceil(data / itemsPerPage);
    let pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <li
          key={i}
          className={`page-item ${i === filter.offset ? "active" : ""}`}
        >
          <a className="page-link _avPointer" onClick={(e) => handlePageChange(i)}>
            {i}
          </a>
        </li>
      );
    }

    return (
      <ul className="pagination ">
        <li className={`page-item ${filter?.offset === 1 ? "disabled" : ""}`}>
          <a
            className="page-link _avPointer"
            onClick={() => handlePageChange(filter?.offset - 1)}
          >
            <IoChevronBack />
          </a>
        </li>
        {pages}
        <li
          className={`page-item ${filter?.offset === totalPages ? "disabled" : ""
            }`}
        >
          <a
            className="page-link _avPointer"
            onClick={() => handlePageChange(filter?.offset + 1)}
          >
            <IoChevronForward />
          </a>
        </li>
      </ul>
    );
  };

  const rejectRequestForConnection = async (data) => {
    handleAcceptLoadingState(data?._id, 'rejectLoading', true);
    try {
      if (loadingBarRef.current) {
        loadingBarRef.current.continuousStart(); // Start the loading bar
      }
      let object = {
        company_id: StorageService.getCompanyId(),
        to_company_id: data?._id
      }
      const res = await rejectConnectionRequest(object);
      if (res?.responseCode === 200) {
        handleAcceptLoadingState(data?._id, 'rejectLoading', false);
        setShowBusinessProfile(false)
        fetchConnectionDataList();
        fetchCountsData();
        toast.success('Reject Connection Request Successfully');
      }
    } catch (error) {
      handleAcceptLoadingState(data?._id, 'rejectLoading', false);
      toast.error('Server side api error !! ');
    } finally {
      handleAcceptLoadingState(data?._id, 'rejectLoading', false);
      if (loadingBarRef.current) {
        loadingBarRef.current.complete(); // Complete the loading bar
      }
    }
  }

  const handleAcceptLoadingState = (id, actionType, isLoading) => {
    setLoadingAccecpt((s) => ({
      ...s,
      [id]: {
        ...s[id],
        [actionType]: isLoading,
      }
    }));
  };

  const acceptRequestForConnection = async (data) => {
    handleAcceptLoadingState(data?._id, 'acceptLoading', true);
    try {
      if (loadingBarRef.current) {
        loadingBarRef.current.continuousStart(); // Start the loading bar
      }
      let object = {
        company_id: StorageService.getCompanyId(),
        to_company_id: data?._id
      }
      const res = await acceptConnectionRequest(object);
      if (res?.responseCode === 200) {
        handleAcceptLoadingState(data?._id, 'acceptLoading', false);
        setShowBusinessProfile(false)
        fetchConnectionDataList();
        fetchCountsData();
        toast.success('Accept Connection Request Successfully');
      }
    } catch (error) {
      handleAcceptLoadingState(data?._id, 'acceptLoading', false);
      toast.error('Server side api error !! ');
    } finally {
      handleAcceptLoadingState(data?._id, 'acceptLoading', false);
      if (loadingBarRef.current) {
        loadingBarRef.current.complete(); // Complete the loading bar
      }
    }
  }

  const handleWithDrawalLoadingState = (id, isLoading) => {
    setLoadingWithDrawal(s => ({ ...s, [id]: isLoading }));
  };

  const withdrawalRequestForConnection = async (data) => {
    handleWithDrawalLoadingState(data?._id, true);
    try {
      if (loadingBarRef.current) {
        loadingBarRef.current.continuousStart(); // Start the loading bar
      }
      let object = {
        company_id: StorageService.getCompanyId(),
        to_company_id: data?._id
      }
      const res = await withDrawalConnectionRequest(object);
      if (res?.responseCode === 200) {
        setShowBusinessProfile(false)
        handleWithDrawalLoadingState(data?._id, false);
        fetchConnectionDataList();
        fetchCountsData();
        toast.success('Withdrawal Connection Request Successfully');
      }
    } catch (error) {
      handleWithDrawalLoadingState(data?._id, false);
      toast.error('Server side api error !! ');
    } finally {
      handleWithDrawalLoadingState(data?._id, false);
      if (loadingBarRef.current) {
        loadingBarRef.current.complete(); // Complete the loading bar
      }
    }
  }

  const handleConnectionLoadingState = (id, isLoading) => {
    setLoadingConnection(s => ({ ...s, [id]: isLoading }));
  };

  const sendRequestForConnection = async (data) => {
    handleConnectionLoadingState(data?._id, true);
    try {
      let object = {
        ...data,
        to_company_id: connectionDetailsGlobal?._id
      }
      const res = await sendConnectionRequest(object);
      if (res?.responseCode === 200) {
        setShowSaveConnect(false);
        fetchConnectionDataList();
        fetchCountsData();
        handleConnectionLoadingState(data?._id, false);
        toast.success('Connection Request Send Successfully');
      }
    } catch (error) {
      handleConnectionLoadingState(data?._id, false);
      toast.error('Server side api error !! ');
    }
  }

  const removeConnectionData = async (data) => {
    setLoadingRemove(true);
    try {
      if (loadingBarRef.current) {
        loadingBarRef.current.continuousStart(); // Start the loading bar
      }
      let object = {
        company_id: StorageService.getCompanyId(),
        to_company_id: data?._id
      }
      const res = await removeConnectionRequest(object);
      if (res?.responseCode === 200) {
        setLoadingRemove(false);
        setShowBusinessProfile(false);
        fetchConnectionDataList();
        fetchCountsData();
        toast.success('Reject Connection Request Successfully');
      }
    } catch (error) {
      setLoadingRemove(false);
      toast.error('Server side api error !! ');
    } finally {
      setLoadingRemove(false);
      if (loadingBarRef.current) {
        loadingBarRef.current.complete(); // Complete the loading bar
      }
    }
  }

  return (
    <>
      <LoadingBar
        color="#ffa800"
        ref={loadingBarRef}
        height={4}
        shadow={true}
        loaderSpeed={1000}
      />
      <Heading title="Transport Directory" />

      <div>
        <div className='addPr_flBx _indentView'>
          <ul className="nav nav-tabs cstNew_tabs px-0" id="addParty" role="tablist">
            {countArray?.map((item, indx) => (
              <li
                className="nav-item"
                role="presentation"
                key={indx + item?.value}
              >
                <button
                  className={
                    "nav-link " + (activeTab === item?.id ? "active" : "")
                  }
                  type="button"
                  aria-selected={activeTab === item?.id}
                  onClick={(e) => { setActiveTab(item.id); setFilter((s) => ({ ...s, offset: 1 })); setConnectionData([]) }}
                  disabled={loading}
                >
                  <span className='_tbTitle'>{item?.title}</span>
                  <span className='_counts'>{item?.count} </span>

                </button>
              </li>
            ))}
          </ul>

          <div className='_connectContainer'>
            {activeTab === 1 && (
              <div className='__conect_mainBg'>
                {
                  (connectionData?.length > 0) ? connectionData?.map((el, ind) => {
                    return (
                      <React.Fragment key={ind}>
                        <CommonBox
                          element={el}
                          // loading={loading}
                          commonProps={commonProps}
                          type={activeTab}
                        />
                      </React.Fragment>
                    )
                  }) : (loading ? 'Loading...' : <span className='no_dataFount'> No Data Found </span>)
                }
              </div>
            )}

            {activeTab === 2 && (
              <div className='__conect_mainBg'>
                {
                  (connectionData?.length > 0) ? connectionData?.map((el, ind) => {
                    return (
                      <React.Fragment key={ind}>
                        <CommonBox
                          element={el}
                          commonProps={commonProps}
                          type={activeTab}
                          id={ind + el?._id}
                          acceptLoading={loadingAccecpt[el?._id]?.acceptLoading || false}
                          rejectLoading={loadingAccecpt[el?._id]?.rejectLoading || false}
                          callRejectRequest={(e) => rejectRequestForConnection(e)}
                          callAcceptRequest={(e) => acceptRequestForConnection(e)}
                        />
                      </React.Fragment>
                    )
                  }) : (loading ? 'Loading...' : <span className='no_dataFount'> No Data Found </span>)
                }
              </div>
            )}

            {activeTab === 3 && (
              <div className='__conect_mainBg'>
                {
                  (connectionData?.length > 0) ? connectionData?.map((el, ind) => {
                    return (
                      <React.Fragment key={ind + el?._id}>
                        <CommonBox
                          element={el}
                          commonProps={commonProps}
                          type={activeTab}
                          id={ind + el?._id}
                          loading={loadingWithDrawal[el?._id] || false}
                          callWithdrawalRequest={(e) => withdrawalRequestForConnection(e)}
                        />
                      </React.Fragment>
                    )
                  }) : (loading ? 'Loading...' : <span className='no_dataFount'> No Data Found </span>)
                }
              </div>
            )}

            {activeTab === 4 && (
              <>
                <div className='__conect_mainBg'>
                  {
                    (connectionData?.length > 0) ? connectionData?.map((el, ind) => {
                      return (
                        <React.Fragment key={ind}>
                          <CommonBox
                            element={el}
                            commonProps={commonProps}
                            type={activeTab}
                            id={ind + el?._id}
                            loading={loadingConnection[el?._id] || false}
                            nextModal={(e) => { setShowSaveConnect(e); setSaveModalDirect((s) => ({ ...s, isOpenDirect: true, isCloseDirect: true })) }}
                          />
                        </React.Fragment>
                      )
                    }) : (loading ? 'Loading...' : <span className='no_dataFount'> No Data Found </span>)
                  }
                </div>
              </>
            )}
          </div>
          <div className='paginationFt_avt '>
            {renderPagination()}
          </div>
        </div>
      </div >

      {showBusinessProfile
        && (
          <ViewProfileModal {...profileModal} />
        )
      }

      {
        showSaveConnect
        && (
          <SaveConnectModal {...saveConnectModal} />
        )
      }

      {
        showAddConnection
        && (
          <AddNewConnectionModal {...addConnectionModal} />
        )
      }
    </>
  )
}

export default Invitations