import { Images } from "constants";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import "../../assets/css/modal.css";
import { UserContext } from "context";
import { CommonInput, CommonModal, CustomSelect } from "components";
import { City, State } from "country-state-city";
import { useDispatch, useSelector } from "react-redux";
import { addCompany, createRouteApi } from "features";
import { StorageServices } from "utils";
import { debounce } from "lodash";
import { getDashbordData, handleKeyDown } from "_services";
import StorageService from "utils/StorageServices/StorageServices";
import { errorSchema, utils } from "helper";
import { getLorryTypeList } from "_services/accountsServices/lrServices";
import toast from "react-hot-toast";

const TruckTypeModal = ({ isOpen, closeModal }) => {
  const {
    masterData,
    cities,
    setCommonError,
    commonError,
    setShowCreateCompanyModal,
    setUserData,
    setCities,
    setMasterData,
    userDetails,
    showCreateCompanyModal,
    setToken,
    userData,
  } = useContext(UserContext);
  const { error, data, loading } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userExst = JSON.parse(StorageService.getLocalItem("userData"));
  const [formValue, setFormValue] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [filteredCities, setFilteredCities] = useState([]);
  const [isloading, setIsLoading] = useState(false);
  const [lorryList, setLorryList] = useState([]);
  const [activeTab, setActiveTab] = useState();
  const [apiError,setApiError] = useState("")

  console.log(activeTab);

  useEffect(() => {
    getDataLorryTypeList();
  }, []);

  const changeActiveTab = async (event, value) => {
    event.preventDefault();
    setActiveTab(value);
  };

  const getDataLorryTypeList = async () => {
    try {
      const res = await getLorryTypeList();
      if (res?.responseCode === 200) {
        setLorryList(res.data?.lorry_type);
        setActiveTab(res.data?.lorry_type[0]);
        setApiError("")
      }else{
        setApiError("Something wrong please Refresh")
      }
    } catch (error) {
      setApiError("Something wrong please Refresh")
    }
  };

  const handleSubmitTruck = async () => {
    const payload = {
      company_id: StorageService.getCompanyId(),
      cities_routes: [],
      states_routes: [],
      lorry_type_id: activeTab?._id,
    };

    try {
      setIsLoading(true);
      const res = await dispatch(createRouteApi(payload));
      if (res?.payload?.responseCode === 200) {
        setIsLoading(false);
        toast.success(res?.payload?.responseMessage);
        closeModal();
        navigate("/dashboard");
      } else {
        setIsLoading(false);
        toast.error(res?.payload?.responseMessage);
      }
    } catch (error) {
      toast.error(error);
      setIsLoading(false);
    }
  };

  return (
    <div>
      {isOpen && (
        <div
          className="modal fade show d-block _addAccount_modal"
          tabIndex="-1"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="home-tab-pane"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                  tabIndex={0}
                >
                  <div className="modal-header ">
                    <h1 className="modal-title " id="staticBackdropLabel">
                      <button
                        className="_modBack_btn"
                        onClick={() => {
                          closeModal();
                        }}
                      >
                        <img src={Images.BACKICON} alt="" />
                      </button>
                      <span>Choose Truck Type</span>
                    </h1>

                    <span
                      onClick={() => {
                        closeModal();
                        navigate("/dashboard");
                      }}
                      className="_stepNum"
                    >
                      Skip
                    </span>
                  </div>

                  <div className="modal-body _trckTypo_body">
                    <div className="_crtComp_modBx">
                      <h3 className="_headH3">
                        Select Truck Type you work with
                      </h3>

                      <div className="_businessType_listOtr">
                        <div className="addVehicle_body addPr_flBx">
                          <ul
                            className="nav nav-tabs cstNew_tabs _truckSelection"
                            id="addParty"
                            role="tablist"
                          >
                            {lorryList?.length > 0 ? (
                              lorryList?.map((item, indx) => (
                                <li className="nav-item" role="presentation">
                                  <button
                                    id={item?.lorry_type}
                                    className={
                                      "nav-link " +
                                      (activeTab?.lorry_type ===
                                      item?.lorry_type
                                        ? "active"
                                        : "")
                                    }
                                    type="button"
                                    aria-selected={
                                      activeTab?.lorry_type === item?.lorry_type
                                    }
                                    onClick={(e, val) =>
                                      changeActiveTab(e, item)
                                    }
                                  >
                                    {item?.lorry_type}
                                  </button>
                                </li>
                              ))
                            ) : !apiError? (
                              <p className="p-2 mt-3"> Loading... </p>
                            ):(<p className="p-2 mt-3"> {apiError}</p>)}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer ">
                <ul
                  className="nav nav-tabs anvTbl_list"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item backTabs" role="presentation">
                    <button
                      className="nav-link active"
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#home-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="home-tab-pane"
                      aria-selected="true"
                    >
                      <img src={Images.BACKICON} alt="" />
                    </button>
                  </li>

                  <li className="nav-item stepRedirect" role="presentation">
                    <button
                      className="anime-btn _submitOn_modal"
                      type="button"
                      disabled={isloading}
                      onClick={handleSubmitTruck}
                    >
                      {isloading ? "Loadding..." : "Complete Setup"}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
      {isOpen && (
        <div className="modal-backdrop fade show" onClick={closeModal}></div>
      )}
    </div>
  );
};

export default TruckTypeModal;
