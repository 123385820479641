import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { CommonDateRange } from 'components'
import StorageService from "utils/StorageServices/StorageServices";
import toast, { Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import BootstrapTable from 'components/BootstrapTable';
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import { utils } from 'helper';
import Tab from 'components/common/Tab';
import { constant } from 'constants/constant';
import Loading from 'pages/Loading';
import moment from 'moment';
import { getFuelStatementData } from '_services/accountsServices/fuelStationServices';

const fuelStatementTabs = [
    { id: 1, title: "Available Fuel", value: "available_fuel", data: 0 },
    { id: 2, title: "Fuel Avg Rate", value: "fuel_avg_rate", data: 0 },
    { id: 3, title: "Fuel Amount", value: "fuel_amount", data: 0 },
]

const FuelStatement = () => {
    let { id } = useParams();
    const [fuelStatementData, setFuelStatementData] = useState([])
    const [loading, setLoading] = useState(false);
    const [tabsCountArray, setTabsCountArray] = useState(fuelStatementTabs)

    const [selectedRange, setSelectedRange] = useState({
        startDate: moment(new Date()).subtract(32, "days"),
        endDate: moment(new Date()),
    });

    const itemsPerPage = 100;
    const [totalItems, setTotalItems] = useState(0);
    const [filter, setFilter] = useState({
        company_id: '',
        vehicle_id: '',
        limit: 100,
        offset: 1,
        status: 1,
    });

    const getDataFuelStatement = async (comp_id, id) => {
        setLoading(true);
        try {
            let object = {
                ...filter,
                company_id: comp_id,
                vehicle_id: id,
                start_date: moment(selectedRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(selectedRange.endDate).format("YYYY-MM-DD"),
            }
            const res = await getFuelStatementData(object);
            if (res?.responseCode == 200) {
                setLoading(false);
                setFuelStatementData(res?.data?.account_statement);
                let newAccountArray = [res?.data?.account_detail?.available_fuel, res?.data?.account_detail?.average_fuel_rate, res?.data?.account_detail?.available_fuel_amount]
                let dataArray = []
                for (let i = 0; i < fuelStatementTabs?.length; i++) {
                    for (let j = 0; j < newAccountArray?.length; j++) {
                        if (i == j) {
                            dataArray.push({ ...fuelStatementTabs[i], data: newAccountArray[j] })
                        }
                    }
                }
                setTabsCountArray(dataArray);
                setTotalItems(res?.data?.total_record);
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            // throw error;
        }
    }

    useEffect(() => {
        if (StorageService.getCompanyId()) {
            getDataFuelStatement(StorageService.getCompanyId(), id);
        }
    }, [StorageService.getCompanyId(), id, filter, selectedRange])

    const columns = [
        { header: "Transaction Date", accessor: "transaction_date" },
        { header: "Branch Name", accessor: "branch_name" },
        { header: "Category", accessor: "category" },
        { header: "Description", accessor: "description" },
        { header: "Rate", accessor: "rate" },
        { header: "Amount", accessor: "amount" },
        { header: "Fuel In", accessor: "fuel_in" },
        { header: "Fuel Out", accessor: "fuel_out" },
        { header: "Balance", accessor: "balance" },
    ];

    let runing_balance = 0;

    // const rowListData = (data) => {
    //     openingBalance += data?.fuel_in - data?.fuel_out
    //     return data?.map((item, i) => ({
    //         ...item,
    //         transaction_date: <>{item?.createdAt ? moment(item?.createdAt).format('DD MMM YYYY') : '-'}</>,
    //         branch_name: <Link to={`details/${item?._id}`}>{item?.branch_id?.branch ? item?.branch_id?.branch : '-'}</Link>,
    //         category: <> {item?.category ? <> {item?.category} </> : '-'} </>,
    //         description: <> {item?.description ? <> {item?.description} </> : '-'} </>,
    //         rate: <>{item?.fuel_rate ? <> &#x20B9; {item?.fuel_rate} </> : '-'}</>,
    //         amount: <>{item?.fuel_amount ? <> &#x20B9; {item?.fuel_amount} </> : '-'}</>,
    //         fuel_in: <> {item?.fuel_in ? <> {item?.fuel_in} Ltr </> : '-'} </>,
    //         fuel_out: <> {item?.fuel_out ? <> {item?.fuel_out} Ltr </> : '-'} </>,
    //         balance: <>  {openingBalance ? openingBalance : 0} Ltr </>,
    //     }));
    // };

    const rowListData = (data) => {
        const listArray = data?.map((item, i) => {
            runing_balance = (typeof item?.fuel_in === 'number' && item?.fuel_in) - (typeof item?.fuel_out === 'number' && item?.fuel_out) + Number(runing_balance)
            return {
                ...item,
                serial: i + 1,
                transaction_date: <>{item?.createdAt ? moment(item?.createdAt).format('DD MMM YYYY') : '-'}</>,
                branch_name: <>{item?.branch_id?.branch ? item?.branch_id?.branch : '-'}</>,
                category: <> {item?.category ? <> {item?.category} </> : '-'} </>,
                description: <div className='_descipTed'> <p>Fuel for Trip {item?.trip_id?.trip_number}</p> <span>Fuel Station- {item?.fuel_station_id?.name} | {item?.description} </span></div>,
                rate: <>{item?.fuel_rate ? <> &#x20B9; {item?.fuel_rate} </> : '-'}</>,
                amount: <>{item?.fuel_amount ? <> &#x20B9; {item?.fuel_amount} </> : '-'}</>,
                fuel_in: <> {item?.fuel_in ? <> {item?.fuel_in} Ltr </> : '-'} </>,
                fuel_out: <> {item?.fuel_out ? <> {item?.fuel_out} Ltr </> : '-'} </>,
                balance: <>  {runing_balance ? runing_balance : 0} Ltr </>,
            }
        });
        return listArray;
    };

    const handlePageChange = (page) => {
        setFilter((s) => ({ ...s, offset: page }))
    };

    const renderPagination = () => {
        const totalPages = Math.ceil(totalItems / itemsPerPage);
        let pages = [];
        for (let i = 1; i <= totalPages; i++) {
            pages.push(
                <li
                    key={i}
                    className={`page-item ${i === filter.offset ? "active" : ""}`}
                >
                    <a className="page-link _avPointer" onClick={(e) => handlePageChange(i)}>
                        {i}
                    </a>
                </li>
            );
        }

        return (
            <ul className="pagination ">
                <li className={`page-item ${filter?.offset === 1 ? "disabled" : ""}`}>
                    <a
                        className="page-link _avPointer"
                        onClick={() => handlePageChange(filter?.offset - 1)}
                    >
                        <IoChevronBack />
                    </a>
                </li>
                {pages}
                <li
                    className={`page-item ${filter?.offset === totalPages ? "disabled" : ""
                        }`}
                >
                    <a
                        className="page-link _avPointer"
                        onClick={() => handlePageChange(filter?.offset + 1)}
                    >
                        <IoChevronForward />
                    </a>
                </li>
            </ul>
        );
    };

    const handleCallBckValue = (data) => {
        setFilter((s) => ({ ...s, status: data }))
    }

    const tabProps = {
        tabList: tabsCountArray,
        callBackTab: handleCallBckValue,
        activeTab: filter?.status,
        invoiceBillRaiseClass: "toggle_bill_invc",
    }

    const handleApply = (newRange) => {
        setSelectedRange(newRange);
        setFilter((s) => ({ ...s, offset: 1 }))
    };

    return (
        <div>
            <div className='_rangeDate_bx'>
                <div className='_fleX_inds'>
                    <div className='_divForth'>
                        <CommonDateRange
                            initialStartDate={selectedRange.startDate}
                            initialEndDate={selectedRange.endDate}
                            onApply={handleApply}
                        />
                    </div>
                </div>
            </div>

            <div className='addPr_flBx _vhlTab_bx'>
                <Tab {...tabProps} />
            </div>

            {loading ? <div><Loading /></div> : <div className='_tabWith_invoiceTab'>
                <BootstrapTable
                    columns={columns}
                    data={rowListData(fuelStatementData?.length > 0 ? fuelStatementData : [])}
                    isLoading={loading}
                />

                <div className='paginationFt_avt '>
                    {renderPagination()}
                </div>
            </div>}

        </div>
    )
}

export default FuelStatement